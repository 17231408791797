import axios from 'axios';

import authInterceptor from './interceptors/auth.interceptor';
import responseInterceptor from './interceptors/response.interceptor';
import errorInterceptor from './interceptors/error.interceptor';

// Sanofi API
const sanofiAPIClient = axios.create({
  baseURL: process.env.VUE_APP_SANOFI_API_URL,
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
});

sanofiAPIClient.interceptors.request.use(authInterceptor);

sanofiAPIClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default sanofiAPIClient;
