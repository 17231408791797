import axios from 'axios';

// Livecast API
const apiv4Client = axios.create({
  baseURL: process.env.VUE_APP_APIV4_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiv4Client;
