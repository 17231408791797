<template>
  <div></div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */

export default {
  name: 'AddToCalendar',
  data() {
    return {};
  },
  async created() {
    /*
    try {
      const { slug: eventId } = this.$route.params;
      const {
        data: { event },
      } = await httpClient.get(`/event/${eventId}`);
      const { link } = this.$route.query;

      if (event && link) {
        const eventInfo = {
          title: event.title,
          start: new Timestamp(
            event.startAt._seconds,
            event.startAt._nanoseconds,
          ).toDate(),
          end: new Timestamp(
            event.endAt._seconds,
            event.endAt._nanoseconds,
          ).toDate(),
          description: link,
        };
        const icsCalendar = ics(eventInfo);

        const hiddenElement = document.createElement('a');

        Object.assign(hiddenElement, {
          href: icsCalendar,
          target: '_blank',
          download: 'calendar.ics',
        });
        hiddenElement.click();
      }
    } catch (error) {
      console.log(error);
    }
    */
  },
};
</script>
<style></style>
