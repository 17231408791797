<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <TextInput
        v-for="formItem in formItemsSorted()"
        :key="formItem._id"
        v-model="form[fieldNameFormat(formItem.name)]"
        :field="formItem"
        :vid="formItem.name"
        :label="formItem.name"
        :formData="form"
      />

      <b-button v-if="!isLoading" type="is-primary" native-type="submit">{{
        $t("join_now")
      }}</b-button>

      <b-button
        v-else
        style="padding-right: 50px !important"
        type="is-primary"
        native-type="submit"
      >
        {{ $t("please_wait") }}
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
      </b-button>
    </form>
  </ValidationObserver>
</template>

<script>
import TextInput from '@/components/regForm/Items/TextInput.vue';
import sanofiAPIClient from '@/api/client';
import { ValidationObserver } from 'vee-validate';
import _ from 'lodash';
import axios from 'axios';
import {getRegistrationPath, createEventUrl} from '@/core/register'

export default {
  components: {
    TextInput,
    ValidationObserver,
  },
  data() {
    return {
      form: {},
      isLoading: false,
      baseUrlList: {
        theatre: 'https://stage.livecasthq.com',
        webinar: 'https://webinar.livecasthq.com',
        live: 'https://live.livecasthq.com',
        irwebcast: 'https://irwebcast.livecasthq.com',
      },
    };
  },
  created(){
    console.log(this.$route.query);
    sanofiAPIClient.get("/register/up")
    axios.get("https://az7a403gze.execute-api.eu-central-1.amazonaws.com/up")
  },
  computed: {
    formItems() {
      return this.$root.event.regForm;
    },
  },
  methods: {
    formItemsSorted() {
      return this.formItems.sort((a, b) => a.order - b.order);
    },
    fieldNameFormat(fieldName) {
      return _.kebabCase(fieldName);
    },
    async onSubmit() {
      const self = this;
      self.isLoading = true;
      console.log(this.$root.event);

      try {
        const data = {
          event_id: this.$root.event._id,
          //send event type to sanofi backend since is doesn't have access to the event service
          event_type: this.$root.event.type,
          info: {
            first_name: this.form['name'],
            last_name: this.form['last-name'],
            tcid: this.form['tc-kimlik-no'],
            birth_year: this.form['dogum-yili'],
            ...this.form,
          }
        };

        if(this.$route.query.ref){
          data.info = {...data.info, ref: this.$route.query.ref}
        }

        const res = await sanofiAPIClient.post("register", data);
        console.log(res);

        const { is_registered: isRegistered} = res.data

        if(isRegistered === false) {
          this.$router.push({
            name: 'thanks',
            params: { slug: this.$root.event._id },
          });
          return;
        }

        this.$router.push({
          name: 'thanks',
          params: { slug: this.$root.event._id },
        });

        /*
        const eventUrl = res.data.livecast_reg && createEventUrl(this.$root.event, res.data.livecast_reg.registration);
        const { emailsEnabled } = this.$root.event.registrationSettings;
        if (emailsEnabled === true) {
          this.$router.push({
            name: 'thanks',
            params: { slug: this.$root.event._id },
          });
        } else {
          window.location.href = eventUrl
        }
        */

      } catch (error) {
        console.log(error);
        console.log(error.response);
        let message
        const status = error.response.status;

        console.log(error.response.status)

        if(status === 500) {
          message = this.$t('unknown_error');
        } else if(status === 400) {
          message = this.$t('invalid_tcid_error');
        } else if (status === 401) {
          message = this.$t('wrong_password');
        } else {
          message = this.$t('access_forbidden');
        }

        self.$buefy.dialog.alert({
          title: this.$t('error'),
          message,
          confirmText: this.$t('okey'),
        });
        self.isLoading = false;
      }
    },
  },
};
</script>

<style>
.field {
  margin-bottom: 1.25rem;
}
button .loading-icon,
button .loading-icon:after {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}
button .loading-overlay {
  justify-content: flex-end !important;
}
</style>
